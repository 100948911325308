export function getLotPlans (lotData) {
  let plans = []
  const plans3D = lotData?.floorList
    ?.filter(floor => floor?.planUrl3D)
    .map(floor => floor.planUrl3D)

  const plans2D = lotData?.floorList
    ?.filter(floor => floor?.planUrl2D)
    .map(floor => floor.planUrl2D)

  plans = plans2D.concat(plans3D)

  return plans
}

export function getLotPlans3D (lotData) {
  const plans3D = lotData?.floorList
    ?.filter(floor => floor?.planUrl3D)
    .map(floor => floor.planUrl3D)

  return plans3D || []
}

export function getLotPlans2D (lotData) {
  const plans2D = lotData?.floorList
    ?.filter(floor => floor?.planUrl2D)
    .map(floor => floor.planUrl2D)

  return plans2D || []
}

export function getLotOriginFloorName (lotData, locale) {
  const originFloorName = lotData?.floorList
    ?.find(floor => floor.floor === lotData?.originFloor)
    ?.name[locale || 'fr']

  return originFloorName
}
