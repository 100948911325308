var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userLotsInfos && _vm.lotSelected
    ? _c("div", { staticClass: "lot" }, [
        _c("h2", { staticClass: "lot-title" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$tc("property.title", _vm.userLotsInfos.length)) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "lot-tabs" }, [
          _c(
            "p",
            {
              staticClass: "lot-tabs__item lot-tabs__item--infos",
              class: { "lot-tabs__item--selected": _vm.UI.infos },
              on: {
                click: function ($event) {
                  _vm.resetTabUI()
                  _vm.UI.infos = true
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("property.tabs.information")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.visuals
            ? _c(
                "p",
                {
                  staticClass: "lot-tabs__item lot-tabs__item--visuals",
                  class: { "lot-tabs__item--selected": _vm.UI.visuals },
                  on: {
                    click: function ($event) {
                      _vm.resetTabUI()
                      _vm.UI.visuals = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("property.tabs.pictures")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.timelineProjectConditions
            ? _c(
                "p",
                {
                  staticClass: "lot-tabs__item lot-tabs__item--timeline",
                  class: { "lot-tabs__item--selected": _vm.UI.timeline },
                  on: {
                    click: function ($event) {
                      _vm.resetTabUI()
                      _vm.UI.timeline = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("property.tabs.timeline")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "lot-main-container__tabs" },
          _vm._l(_vm.userLotsInfos, function (userLot, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "lot-main-container__title",
                class: {
                  "lot-main-container__title--disabled":
                    userLot.flatNumber !== _vm.lotSelected.flatNumber,
                },
                on: {
                  click: function ($event) {
                    return _vm.updateLotSelected(userLot)
                  },
                },
              },
              [
                _c("p", { staticClass: "lot-main-container__lotNumber" }, [
                  _vm._v(
                    "\n        LOT " + _vm._s(userLot.flatNumber) + "\n      "
                  ),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "lot-main-container" },
          [
            _vm.UI.infos && _vm.lotSelected && _vm.UI.infosReady
              ? _c(
                  "div",
                  { staticClass: "lot-infos-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "lot-infos__aside" },
                      [
                        _c("div", { staticClass: "aside-main" }, [
                          _c("div", { staticClass: "aside-main__card" }, [
                            _c("div", { staticClass: "aside-main__boxes" }, [
                              _vm.lotSelected.price && _vm.lotSelected.price > 0
                                ? _c("p", { staticClass: "label-infos" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.lotSelected.price
                                          ).toLocaleString()
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.lotSelected.priceMeasurement
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.lotSelected.type
                                ? _c("p", { staticClass: "label-infos" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.lotSelected.type) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.lotSelected.originFloor
                                ? _c("p", { staticClass: "label-infos" }, [
                                    _vm.lotSelected.originFloor !== 0
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "property.information.boxes.floor"
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getLotOriginFloorName(
                                            _vm.lotSelected,
                                            _vm.locale
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.bedrooms.length
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "label-infos label-infos--count",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "property.information.boxes.bedrooms"
                                              )
                                              .toUpperCase()
                                          ) +
                                          " x" +
                                          _vm._s(_vm.bedrooms.length) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.bathrooms.length
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "label-infos label-infos--count",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm
                                              .$t(
                                                "property.information.boxes.bathrooms"
                                              )
                                              .toUpperCase()
                                          ) +
                                          " x" +
                                          _vm._s(_vm.bathrooms.length) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.lotSelected.surfaceTotal &&
                          _vm.lotSelected.surfaceTotal != "0"
                            ? _c("div", { staticClass: "aside-main__card" }, [
                                _c(
                                  "h3",
                                  { staticClass: "aside-main__surfaces" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("property.surfaces.title")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "aside-main__text" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("property.surfaces.interior")
                                      ) +
                                      " : " +
                                      _vm._s(_vm.lotSelected.surfaceTotal) +
                                      " " +
                                      _vm._s(
                                        _vm.lotSelected.surfaceMeasurement
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.lotSelected.surfaceExteriorTotal !== "0"
                                  ? _c(
                                      "p",
                                      { staticClass: "aside-main__text" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "property.surfaces.exterior"
                                              )
                                            ) +
                                            " : " +
                                            _vm._s(
                                              _vm.lotSelected
                                                .surfaceExteriorTotal
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.lotSelected.surfaceMeasurement
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "surfaces-wrapper aside-main__card",
                            },
                            [
                              (_vm.surfaceIntern && _vm.surfaceIntern.length) ||
                              (_vm.surfaceExtern && _vm.surfaceExtern.length)
                                ? _c(
                                    "div",
                                    { staticClass: "surfaces-wrapper__items" },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "aside-main__surfaces" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "property.surfaces.byRooms"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        { staticClass: "infos-list" },
                                        _vm._l(
                                          _vm.surfaceIntern,
                                          function (room, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass:
                                                  "infos-list__items",
                                              },
                                              [
                                                room &&
                                                room.surface &&
                                                room.name
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          room.name[_vm.locale]
                                                        ) +
                                                          " : " +
                                                          _vm._s(room.surface) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.lotSelected
                                                              .surfaceMeasurement
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        { staticClass: "infos-list" },
                                        _vm._l(
                                          _vm.surfaceExtern,
                                          function (room, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass:
                                                  "infos-list__items",
                                              },
                                              [
                                                room &&
                                                room.surface &&
                                                room.name
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          room.name[_vm.locale]
                                                        ) +
                                                          " : " +
                                                          _vm._s(room.surface) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.lotSelected
                                                              .surfaceMeasurement
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.UI.gallery &&
                        _vm.galleryContent &&
                        _vm.galleryContent.length
                          ? _c("Gallery", {
                              staticClass: "lot-infos__gallery--mobile",
                              attrs: { gallery: _vm.galleryContent },
                              on: {
                                clickOnImage: function ($event) {
                                  return _vm.requestFullscreen($event)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "aside-footer" }, [
                          _vm.UI.infos
                            ? _c(
                                "button",
                                {
                                  staticClass: "cta lot-infos-cta",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openStreamingLink(
                                        _vm.lotSelected.flatNumber
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.visitLinkText) +
                                      " "
                                  ),
                                  _c("img", {
                                    staticClass: "lot-infos-cta-image",
                                    attrs: {
                                      src: "/icon/icon-forward-white.png",
                                      alt: "icon",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.UI.gallery && _vm.lotSelected
                      ? _c("Gallery", {
                          staticClass: "lot-infos__gallery--desktop",
                          attrs: { gallery: _vm.galleryContent },
                          on: {
                            clickOnImage: function ($event) {
                              return _vm.requestFullscreen($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.UI.plans
              ? _c(
                  "section",
                  { staticClass: "lot-plan2D" },
                  [
                    _vm.UI.gallery
                      ? _c("Gallery", {
                          attrs: { gallery: _vm.galleryContent },
                          on: {
                            clickOnImage: function ($event) {
                              return _vm.requestFullscreen($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.UI.galleryFullscreen && _vm.lotSelected
              ? _c("GalleryFullscreenZoom", {
                  attrs: {
                    gallery: _vm.galleryContent,
                    "gallery-index": _vm.galleryFullscreenIndex,
                  },
                  on: {
                    BtnCloseClicked: function ($event) {
                      return _vm.resetGalleryFullscreen()
                    },
                    clickOutside: function ($event) {
                      return _vm.resetGalleryFullscreen()
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.UI.visuals
              ? _c("section", { staticClass: "lot-visuals" }, [
                  _vm._v("\n      This is my images\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.UI.timeline && _vm.program.timeline_project.length > 0
              ? _c("section", { staticClass: "dashboard-card" }, [
                  _c("h3", { staticClass: "lot-infos__timeline" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("dashboard.timeline.title")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "timeline" }, [
                    _c(
                      "ul",
                      { staticClass: "timeline__box" },
                      _vm._l(
                        _vm.program.timeline_project,
                        function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "timeline__item",
                              class: {
                                "timeline__item--checked": item.done === true,
                              },
                            },
                            [
                              _c("div", [
                                _c("p", { staticClass: "timeline__heading" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.title) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "timeline__subtitle" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.description) +
                                      "\n              "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "lot" }, [
        _c("h3", { staticClass: "lot-title" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$tc("property.title", _vm.userLotsInfos.length)) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("property.empty")))]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }