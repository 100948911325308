<template>
  <div class="documents">
    <h2 class="documents__title">
      {{ $t('documents.title') }}
    </h2>
    <div>
      <div class="documents__content">
        <div v-if="choiceSheets && choiceSheets.length > 0" class="documents-files">
          <h3 class="documents__subtitle">
            {{ $t('documents.files.subtitle') }}
          </h3>
          <div class="lot-selector">
            <span for="lotnumber" class="lot-selector__label"> LOT : </span>
            <select v-model="lotSelected" class="lot-selector__select">
              <option v-for="(item, index) in lotIdList" :key="index" :value="item" class="lot-selector__options">
                {{ item }}
              </option>
            </select>
          </div>
          <ul v-if="choiceSheets && choiceSheets.length > 0 && selectedChoicesheet" class="documents__list">
            <DocumentsItem :doc="selectedChoicesheet" class="documents__news" @docClicked="setDocAsRead(selectedChoicesheet.id)">
              <a v-if="selectedChoicesheet.pdf_url && selectedChoicesheet.pdf_url.includes('.pdf')" :url="selectedChoicesheet.pdf_url" class="documents__link" @click="openDoc(selectedChoicesheet.id, selectedChoicesheet.pdf_url, selectedChoicesheet.read)">
                {{ $t('documents.open') }}
              </a>
            </DocumentsItem>
          </ul>
        </div>
        <div v-if="program.timeline_finance && program.timeline_finance.length > 0" class="documents-timeline">
          <h3 class="documents__subtitle">
            {{ $t('documents.timeline.title') }}
          </h3>
          <div class="timeline">
            <ul class="timeline__box">
              <li v-for="(item, index) in program.timeline_finance" :key="index" class="timeline__item" :class="{'timeline__item--checked': item.done === true}">
                <div>
                  <p class="timeline__heading">
                    {{ item.title }}
                  </p>
                  <p class="timeline__subtitle">
                    {{ item.description }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="(!choiceSheets && !program.timeline_finance) || ((choiceSheets && choiceSheets.length === 0 ) && program.timeline_finance.length === 0)">
        <p class="documents__content--empty">
          {{ $t('documents.files.noDocuments') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import axios from 'axios'
import DocumentsItem from '~/components/Documents/DocumentsItem'
import { getHeaders } from '~/plugins/headers'
import { CUSTOMERAREA } from '~/plugins/constants'
import { getRouteForContext } from '~/services/apiService'

export default {
  components: {
    DocumentsItem,
  },
  data () {
    return {
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      lotSelected: '',
      lotIdList: [],
    }
  },
  computed: {
    ...mapGetters({
      program: 'program/getProgramData',
      choiceSheets: 'choiceSheet/getChoiceSheets',
      userFlats: 'getUserFlats',
    }),
    selectedChoicesheet () {
      if (this.lotSelected && this.lotIdList) {
        for (const sheet of this.choiceSheets) {
          if (sheet.flat_id === this.lotSelected) {
            return sheet
          }
        }
      }
      return null
    },
  },
  async mounted () {
    const choicesheets = await this.choiceSheets
    if (choicesheets && choicesheets.length > 0) {
      this.setFlatIdList(choicesheets)
    }
  },
  methods: {
    setFlatIdList (choicesheets) {
      const idList = []
      for (const sheet of choicesheets) {
        if (!idList.includes(sheet.flat_id)) {
          idList.push(sheet.flat_id)
        }
      }
      this.lotIdList = idList
      this.lotSelected = idList[0]
    },
    openDoc (id, url, readStatus) {
      this.openLink(url)
      if (readStatus === false) {
        this.setDocAsRead(id)
      }
    },
    async setDocAsRead (id) {
      if (!this.clientParams) {
        await this.setNotificationAsRead(id)
        const responseChoiceSheets = await this.getChoiceSheetNotifications()
        if (responseChoiceSheets.status === 200) {
          const choiceSheets = responseChoiceSheets.data.choicesheets
          const choicesheetNotifications = this.getNotificationsLength(choiceSheets, this.userFlats)
          this.storeChoiceSheets(choiceSheets)
          this.storeChoiceSheetsNotifications(choicesheetNotifications)
        }
      }
    },
    openLink (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    async setNotificationAsRead (id) {
      const token = await Cookies.get('temp-usr')

      try {
        const response = await axios.post(getRouteForContext('choice-sheet-notifications', []) + `${id}`, null, { headers: getHeaders(token) })
        return response
      } catch (error) {
        return error
      }
    },
    async getChoiceSheetNotifications () {
      const token = Cookies.get('temp-usr')

      try {
        const response = await axios.get(getRouteForContext('choice-sheet-notifications', []), { headers: getHeaders(token) })
        return response
      } catch (error) {
        return error
      }
    },
    getNotificationsLength (choicesheets, userFlats) {
      let notifications = 0
      for (const flat of userFlats) {
        const lastFlatNotification = choicesheets.filter(sheet => sheet.flat_id === flat)
        if (lastFlatNotification[0] && lastFlatNotification[0].read === false) {
          notifications++
        }
      }
      return notifications
    },
    storeChoiceSheets (choicesheets) {
      if (choicesheets) {
        this.$store.commit('choiceSheet/SET_CHOICE_SHEETS', choicesheets)
      }
    },
    storeChoiceSheetsNotifications (notifications) {
      this.$store.commit('choiceSheet/SET_CHOICE_SHEETS_NOTIFICATIONS', notifications)
    },
  },
}
</script>

<style lang="scss" scoped>
.documents {
  padding: min(2rem, 6%);
  overflow-x: auto;
  background-color: $main-light-color;
  animation: reveallight 600ms;

  @media (min-width: $breakpoint-medium) {
    height: 100%;
    object-fit: contain;
    padding: 0 3rem 3rem;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 3rem 3rem;
  }

  &__title {
    @extend %ext-title;
  }

  &__subtitle {
    @extend %ext-subTitle;
    color: var(--second-option-color);
    padding-bottom: 1rem;
  }

  &__text {
    @extend %ext-text;
  }

  &__content {
    @media (min-width: $breakpoint-large) {
      display: flex;
    }
    &--empty {
      margin-bottom: 2rem;
    }
  }

  &__list {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }
    padding: 0.4rem 1rem;
    min-height: 20rem;

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: $bg-color;
      -webkit-box-shadow: 0 0 1px $bg-color;
    }
  }
  &__list :first-child {
    border-top: none;
  }

  &__news,
  &__link {
    @media (hover: hover) {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__link {
    color: var(--second-option-color)
  }
}

.documents-files,
.documents-timeline {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-large) {
    width: 50%;
  }
}

.documents-files {
  margin-right: 1rem;
}

.lot-selector {
  display: flex;
  align-items: center;

  &__label {
    margin-right: 0.4rem;
  }

  &__select {
    border: solid $border-color 1px;
    border-radius: $border-radius;
    padding: 0 0.6rem;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        background-color: $bg-color;
        cursor: pointer;
      }
    }
  }
}
</style>
