<template>
  <section v-if="programData" class="theHeader">
    <div class="theHeader__menu">
      <nuxt-link v-if="userStatus ==='acquirer'" :to="{ name: clientParams ? 'client-lotId-home' : 'lotId-home', params: { lotId: lotIdParams } }">
        <button class="menu__btn menu__btn--active">
          <img src="/icon/icon-new-home.png" alt="icon">
        </button>
      </nuxt-link>
      <button v-else class="menu__btn menu__btn--disabled" disabled>
        <img src="/icon/icon-new-home.png" alt="icon">
      </button>
      <div v-if="isAuthenticated" class="menu__user">
        <p v-if="loggedInUser" class="menu__userinfos" @click="UI.userinfos = true">
          {{ loggedInUser.firstName }} {{ loggedInUser.lastName }}
        </p>
        <button class="menu__logout" @click="logout()">
          {{ $t('ctaLogout') }}
        </button>
      </div>
      <nuxt-link v-else :to="{ name: clientParams ? 'client-lotId-login' : 'lotId-login', params: { lotId: lotIdParams } }" class="menu__link">
        {{ $t('ctaLogin') }}
      </nuxt-link>
      <p class="menu__link" @click="UI.language = true">
        {{ actualLanguage.toUpperCase() }}
      </p>
    </div>
    <img class="theHeader__logo" :src="programLogoFiltered" alt="logo">
    <h1 class="theHeader__title">
      <span v-if="programData.title" class="theHeader__title--bold">{{ programData.title }} </span> - {{ programData.programCity }}
    </h1>
    <UserInfos v-if="UI.userinfos" :user="loggedInUser" @clickOutside="UI.userinfos = false" @closeModal="UI.userinfos = false" />
    <Language v-if="UI.language" @clickOutside="UI.language = false" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { CUSTOMERAREA } from '~/plugins/constants'

export default {
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      UI: {
        userinfos: false,
        language: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      programData: 'program/getProgramData',
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
      programNameForUrl: 'program/getProgramNameForUrl',
      userStatus: 'user/getUserStatus',
    }),
    actualLanguage () {
      return this.$i18n.locale
    },
    programLogoFiltered () {
      const logo = this.programData
        ? this.programData.programLogo || this.programData.promoterInfo.logo.main
        : null
      return logo ? this.$prefixLinks(logo) : null
    },
  },
  methods: {
    logout () {
      this.$resetApp(this.$route.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.theHeader {
  background-color: $bg-color;
  display: block;
  text-align: center;
  padding: 1.4rem;

  @media (min-width: $breakpoint-large) {
    height: $header-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;
    z-index: $z-index-navigation;

    @media (min-width: $breakpoint-large) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: $fs-main-title;
    margin: 1rem auto 0;

    @media (min-width: $breakpoint-large) {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 1.5rem;
      margin: auto;
    }

    &--bold {
      font-weight: 700;
      font-size: $fs-main-title;
    }
  }

  &__logo {
    max-width: 50%;
    max-height: 3.75rem;

    @media (min-width: $breakpoint-large) {
      width: auto;
      height: 4.25rem;
    }
  }
}

.menu {
  &__link {
    font-size: $fs-link;
    font-weight: 500;
    text-align: left;
    margin-left: 1.6rem;

    &:last-child {
      margin-left: 3.2rem;
    }
  }

  &__user {
    font-size: $fs-link;
    font-weight: 600;
    text-align: left;
    margin-left: 1.6rem;
  }

  &__logout {
    font-weight: 300;
  }

  &__link,
  &__logout,
  &__userinfos {
    transition: text-decoration 200ms, color 200ms;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: var(--second-option-color);
    }
  }

  &__btn {
    border-radius: 50%;
    background-color: $btn-main-color;
    box-shadow: 0px 0px 14px 4px #e0e0e0;
    width: 4.25rem;
    height: 4.25rem;
    padding: 1.2rem;
    transition: box-shadow 200ms, background-color 200ms;

    &--active {
      &:hover {
        box-shadow: 0px 0px 14px 2px grey;
      }
    }
    &--disabled {
      opacity: 0.5;
      &:hover {
        box-shadow: 0;
      }
    }
  }
}
</style>
