var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project
    ? _c(
        "div",
        { staticClass: "project-card" },
        [
          _c("h3", { staticClass: "project-card__heading" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("projects.project")) + " "),
            _vm.project.changeName
              ? _c(
                  "span",
                  {
                    staticClass:
                      " project-card__heading project-card__heading--bold",
                  },
                  [_vm._v(" " + _vm._s(_vm.project.changeName))]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "project-card__img",
            attrs: {
              src: _vm.$prefixLinks(_vm.programData.background),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "project-card__infos" }, [
            _vm.project.formatted_date
              ? _c("p", { staticClass: "project-card__date" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.project.formatted_date) + "\n    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.project.flatId
            ? _c("p", { staticClass: "project-card__lot" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("flat.designation")) +
                    " " +
                    _vm._s(_vm.project.flatId) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectLotInfos
            ? _c("div", { staticClass: "project-card__desc" }, [
                _vm.lotAndLayoutPrice(_vm.projectLotInfos.flatLayout) &&
                _vm.lotAndLayoutPrice(_vm.projectLotInfos.flatLayout) > 0
                  ? _c("p", { staticClass: "project-card__desc__price" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.lotAndLayoutPrice(
                              _vm.projectLotInfos.flatLayout
                            )
                          ) +
                          " " +
                          _vm._s(_vm.projectLotInfos.priceMeasurement) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectLotInfos.surfaceTotal > 0
                  ? _c("p", { staticClass: "project-card__desc__surface" }, [
                      _vm._v(
                        "\n      - " +
                          _vm._s(_vm.projectLotInfos.surfaceTotal) +
                          " " +
                          _vm._s(_vm.projectLotInfos.surfaceMeasurement) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectLotInfos.flatLayout
                  ? _c(
                      "p",
                      { staticClass: "project-card__desc__layout-name" },
                      [
                        _vm._v(
                          "\n      - " +
                            _vm._s(_vm.$t("plan")) +
                            " " +
                            _vm._s(
                              _vm.getLayoutName(_vm.projectLotInfos.flatLayout)
                            ) +
                            "\n    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "project-card__actions" }, [
            _c(
              "button",
              {
                staticClass: "project-card__cta",
                class: {
                  "project-card__cta--disabled": _vm.clientParams,
                  "project-card__cta--ghost": !_vm.clientParams,
                },
                attrs: { disabled: _vm.clientParams },
                on: {
                  click: function ($event) {
                    return _vm.deleteProject(
                      _vm.project.subProgramId,
                      _vm.project.flatId,
                      _vm.project.creationDate,
                      _vm.project.changeName
                    )
                  },
                },
              },
              [_c("img", { attrs: { src: "/icon/icon-trash.png", alt: "" } })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "project-card__cta",
                on: {
                  click: function ($event) {
                    return _vm.openProjectStreaming(
                      _vm.programId,
                      _vm.project.subProgramId,
                      _vm.project.flatId,
                      _vm.project.creationDate
                    )
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("projects.open")) + "\n    ")]
            ),
          ]),
          _vm._v(" "),
          _vm.UI.deleteProject
            ? _c("ProjectsDelete", {
                attrs: {
                  checksum: _vm.projectChecksum,
                  "project-name": _vm.projectName,
                },
                on: {
                  closeWindowRequest: function ($event) {
                    return _vm.closeDeleteProjectUI()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }