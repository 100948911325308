import axios from 'axios'
import { getRouteForContext } from '~/services/apiService'

export const state = () => ({
  projects: null,
  filteredProjects: null,
  lots: [],
})

export const actions = {
  loadProjects ({ dispatch, commit }, { headers, programId, flatId, subProgramId }) {
    if (flatId && subProgramId) {
      return axios.get(`/moder_1_5/cart/shopping?subProgramId=${subProgramId}&flatId=${flatId}&behalf=true`, { headers })
        .then((response) => {
          commit('SET_PROJECTS', response.data.data)
          return response.data.data
        })
        .then(async () => {
          await dispatch('filterProjectsAndAddlotInfos', programId)
        })
        .catch((error) => {
          return error
        })
    } else {
      return axios.get(getRouteForContext('cart', []), { headers })
        .then((response) => {
          commit('SET_PROJECTS', response.data.data)
          return response.data.data
        })
        .then(async () => {
          await dispatch('filterProjectsAndAddlotInfos', programId)
        })
        .catch((error) => {
          return error
        })
    }
  },
  filterProjectsAndAddlotInfos ({ state, commit }, programId) {
    const filteredProjects = []
    axios.get(`/moder_1_5/programs/${programId}/sub-programs`)
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          for (let j = 0; j < state.projects.length; j++) {
            if (response.data.data[i].subProgramId === state.projects[j].subProgramId) {
              if (response.data.data[i].freeFlats) {
                for (let x = 0; x < response.data.data[i].freeFlats.length; x++) {
                  let projectUpdate
                  if (response.data.data[i].freeFlats[x].flatNumber.toLowerCase() === state.projects[j].flatId.toLowerCase()) {
                    projectUpdate = state.projects[j]
                    projectUpdate.infos = response.data.data[i].freeFlats[x]
                    filteredProjects.push(projectUpdate)
                  }
                }
              }
            }
          }
        }
      })
      .then(() => {
        filteredProjects.sort((a, b) => (a.creationDate > b.creationDate) ? 1 : -1)
        commit('SET_FILTERED_PROJECTS', filteredProjects.reverse())
        commit('DELETE_UNUSED_PROJECTS')
      })
      .catch((error) => {
        return error
      })
  },
}

export const mutations = {
  SET_PROJECTS: (state, projects) => {
    if (projects) {
      state.projects = projects
    }
  },
  SET_FILTERED_PROJECTS: (state, filterProjects) => {
    state.filteredProjects = filterProjects
  },
  DELETE_UNUSED_PROJECTS: (state) => {
    state.projects = null
  },
  SET_STATUS_READY: (state) => {
    state.stateReady = true
  },
  RESET_PROJECTS: (state) => {
    state.lots = []
    state.projects = null
    state.filteredProjects = null
  },
}

export const getters = {
  projects: (state) => {
    return state.filteredProjects
  },
  lots: (state) => {
    return state.lots
  },
  stateReady: (state) => {
    return state.stateReady
  },
}
