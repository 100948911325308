import axios from 'axios'
import { getRouteForContext } from '~/services/apiService'

export const state = () => ({
  lots: [],
  lotsLength: 0,
  lotSubProgramId: '',
  lotStreamingLink: null,
  subPrograms: [],
})

export const actions = {
  async loadSubprogram ({ commit }, { lotsList, programId }) {
    const userLots = []
    const response = await axios.get(getRouteForContext('sub-programs', [programId]))
    for (let i = 0; i < response.data.data.length; i++) {
      if (response.data.data[i].freeFlats) {
        for (let x = 0; x < response.data.data[i].freeFlats.length; x++) {
          for (const lotNumber of lotsList) {
            if (response.data.data[i].freeFlats[x].flatNumber.toLowerCase() === lotNumber.toLowerCase()) {
              const lot = response.data.data[i].freeFlats[x]
              lot.subProgramId = response.data.data[i].subProgramId
              lot.roomCount = response.data.data[i].roomCount
              lot.typology = response.data.data[i].typologys
              lot.type = response.data.data[i].type
              userLots.push(lot)
            }
          }
        }
      }
    }
    commit('SET_LOTS', userLots)
    commit('SET_LOTS_LENGTH', userLots.length)
    commit('SET_SUBPROGRAMS', response.data.data)
    return { status: response.status, userLots }
  },
}

export const mutations = {
  SET_LOTS: (state, lot) => {
    if (lot) {
      state.lots = lot
    }
  },
  SET_LOTS_LENGTH: (state, length) => {
    state.lotsLength = length
  },
  SET_SUBPROGRAMS: (state, subPrograms) => {
    state.subPrograms = subPrograms
  },
  RESET_LOT: (state) => {
    state.lots = []
    state.lotsLength = 0
    state.lotSubProgramId = ''
    state.lotStreamingLink = null
  },
  SET_STREAMING_LINK: (state, lotLink) => {
    state.lotStreamingLink = lotLink
  },
}

export const getters = {
  getLot: (state) => {
    return state.lots
  },
  getLotsLength: (state) => {
    return state.lotsLength
  },
  getLotSubProgramId: (state) => {
    return state.lotSubProgramId
  },
  getStreamingLink: (state) => {
    return state.lotStreamingLink
  },
}
