<template>
  <div class="modal-container" @click="closeOnClickOutside('modal-container')">
    <section v-if="gallery" class="carousel-wrapper-fullscreen">
      <div ref="mySwiper" v-swiper="swiperOption">
        <UiBtnClose class="swiper-close-btn" @BtnCloseClicked="$emit('BtnCloseClicked')" />
        <div class="swiper-wrapper">
          <div v-for="(item, index) in gallery" :key="index" class="swiper-slide">
            <div class="swiper-zoom-container">
              <img :key="index" :src="item">
            </div>
          </div>
        </div>
        <div v-if="gallery.length > 1" class="swiper-pagination" />
        <div v-if="gallery.length > 1" class="swiper-button-prev" aria-label="prev" />
        <div v-if="gallery.length > 1" class="swiper-button-next" aria-label="next" />
      </div>
    </section>
  </div>
</template>

<script>
import 'swiper/swiper.scss'
import { directive } from 'vue-awesome-swiper'
import UiBtnClose from '~/components/UI/UiBtnClose'

export default {
  components: {
    UiBtnClose,
  },
  directives: {
    swiper: directive,
  },
  props: {
    gallery: {
      type: Array,
      default () {
        return null
      },
    },
    galleryIndex: {
      type: String,
      default: '0',
    },
  },
  data () {
    return {
      swiperOption: {
        initialSlide: this.galleryIndex,
        zoom: {
          maxRatio: 2.2,
          minRatio: 1,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      },
      ftp: process.env.FTP,
    }
  },
  mounted () {
    document.body.classList.add('modal-open')
  },
  beforeDestroy () {
    document.body.classList.remove('modal-open')
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList.value === containerClass) {
        this.$emit('clickOutside')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.swipper-fullscreen-container {
  z-index: $z-index-modal;
  background-color: $modal-bg-color;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  animation: reveallight 400ms;
}

.carousel-wrapper-fullscreen {
  width: 100%;
  height: auto;
  margin: auto;

  @media (min-width: $breakpoint-large) {
    width: 80%;
    height: auto;
    margin: auto;
  }
}

.swiper {
  height: 100%;
}

.swiper-slide {
  margin: auto;
  &:hover {
    cursor: pointer;
  }
}

.swiper-close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: background-color 200ms, border 200ms;
  background-color: $transparent-light-color !important;

  &:hover {
    background-color: $transparent-medium-color !important;
  }

  @media (max-width: $breakpoint-small) {
    display: none;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.8rem;
}
</style>
