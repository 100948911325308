import axios from 'axios'
import { getConvertColor } from '@/plugins/convertColorToHex.js'
import { waitTimer } from '~/plugins/helpers'
import { getRouteForContext } from '~/services/apiService'

export const state = () => ({
  programData: '',
  programNameForUrl: '',
  promoterForUrl: '',
  programStreamingLink: null,
})

export const actions = {
  async loadProgramGlobal ({ commit, dispatch }, { programName, promoterName }) {
    if (programName && promoterName) {
      return await axios.get(getRouteForContext(
        'program-info',
        [promoterName, programName]
      ))
        .then(async (response) => {
          commit('SET_PROGRAM', response.data.programInfo)
          commit('SET_PROGRAM_URL', programName)
          commit('SET_PROMOTER_URL', promoterName)
          dispatch('setOptionsColors', response.data.programInfo.promoterInfo.colors)
          await waitTimer(50)
          return response
        })
        .catch((e) => {
          return e.response
        })
    }
  },
  setOptionsColors ({ commit }, programColors) {
    const optionColors = []
    if (programColors) {
      for (let i = 0; i < programColors.length; i++) {
        const newColor = getConvertColor(programColors[i])
        optionColors.push(newColor)
      }
    }
    const element = document.querySelector('body')
    element.style.setProperty('--main-option-color', optionColors[0] ? optionColors[0] : '#1A1A1Aff')
    element.style.setProperty('--second-option-color', optionColors[1] ? optionColors[1] : '#746649ff')
    element.style.setProperty('--third-option-color', optionColors[2] ? optionColors[2] : '#1A1A1Aff')
  },
}

export const mutations = {
  SET_PROGRAM: (state, program) => {
    if (program) {
      state.programData = program
    }
  },
  RESET_PROGRAM: (state) => {
    state.programData = ''
    state.programNameForUrl = ''
    state.promoterForUrl = ''
    state.programStreamingLink = null
  },
  SET_PROGRAM_URL: (state, programName) => {
    state.programNameForUrl = programName
  },
  SET_PROMOTER_URL: (state, promoter) => {
    state.promoterForUrl = promoter
  },
  SET_STREAMING_LINK: (state, programLink) => {
    state.programStreamingLink = programLink
  },
}

export const getters = {
  getProgramData: (state) => {
    return state.programData
  },
  getStreamingLink: (state) => {
    return state.programStreamingLink
  },
  getProgramNameForUrl: (state) => {
    return state.programNameForUrl
  },
  commercialInfos: (state) => {
    if (state.programData.commercials) {
      return state.programData.commercials
    }
  },
  programId: (state) => {
    return state.programData.programId
  },
  getProgramPromoterName: (state) => {
    return state.programData.promoterInfo ? state.programData.promoterInfo.name : null
  },
}
