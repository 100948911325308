<template>
  <section class="dashboard">
    <h2 class="dashboard__title">
      {{ $t('dashboard.title') }}
    </h2>
    <div class="dashboard-content">
      <section class="dashboard-card dashboard-card-notifications">
        <h3 class="dashboard-card__title">
          {{ $t('dashboard.notifications.title') }}
        </h3>
        <div class="dashboard-card-notifications-content">
          <div class="dashboard-card-notifications-content__notifications">
            <nuxt-link :to="{ name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }">
              <Notifications :type="'lotId-messages'" :unread="messagesNotifications || 0 " />
            </nuxt-link>
            <nuxt-link :to="{ name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }">
              <Notifications :type="'news'" :unread="newsNotifications || 0" />
            </nuxt-link>
          </div>
          <div class="dashboard-card-notifications-content__lastContent">
            <nuxt-link :to="{ name: clientParams ? 'client-lotId-messages' : 'lotId-messages', params: { lotId: lotIdParams } }" class="dashboard-card-notifications-content__lastContent-item">
              <DashboardNotification v-if="lastMessage" :content="lastMessage.subject" :title="$t('dashboard.messages.subtitle')" :date="lastMessage.time" />
            </nuxt-link>
            <nuxt-link :to="{ name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }" class="dashboard-card-notifications-content__lastContent-item">
              <DashboardNotification v-if="lastNews" :content="lastNews.title" :title="$t('dashboard.news.lastNews')" :date="lastNews.created_at" />
            </nuxt-link>
          </div>
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-lot' : 'lotId-lot', params: { lotId: lotIdParams } }" class="dashboard-card-notifications-content__lastContent-item">
            <DashboardNotification v-if="userStatus ==='acquirer' && lastTimelineProject" :content="lastTimelineProject.title" :title="$t('dashboard.timeline.project')" :date="lastTimelineProject.date" class="dashboard-card-notifications-content__lastContent-item" />
          </nuxt-link>
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-docs' : 'lotId-docs', params: { lotId: lotIdParams } }" class="dashboard-card-notifications-content__lastContent-item">
            <DashboardNotification v-if="lastTimelineFinance && userStatus === 'acquirer'" :content="lastTimelineFinance.title" :title="$t('dashboard.timeline.finance')" :date="lastTimelineFinance.date" class="dashboard-card-notifications-content__lastContent-item" />
          </nuxt-link>
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-construction' : 'lotId-construction', params: { lotId: lotIdParams } }" class="dashboard-card-notifications-content__lastContent-item">
            <DashboardNotification v-if="lastTimelineConstruction" :content="lastTimelineConstruction.title" :title="$t('dashboard.timeline.construction')" :date="lastTimelineConstruction.date" class="dashboard-card-notifications-content__lastContent-item" />
          </nuxt-link>
        </div>
      </section>
      <div class="dashboard-card-wrapper-2">
        <section v-if="userStatus ==='acquirer' && lots" class="dashboard-card dashboard-card-lot">
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-lot' : 'lotId-lot', params: { lotId: lotIdParams } }">
            <h3 class="dashboard-card__title">
              {{ $tc('dashboard.lot.title', lots.length ) }}
            </h3>
          </nuxt-link>
          <div v-if="userLotSelected">
            <div v-for="(userLot, index) in lots" :key="index" class="dashboard-card-lot__tab" :class="{'dashboard-card-lot__tab--disabled': userLot.flatNumber !== userLotSelected.flatNumber}" @click="userLotSelected = userLot">
              LOT {{ userLot.flatName }}
            </div>
          </div>
          <div v-if="userLotSelected" class="dashboard-card-lot__content">
            <div class="dashboard-card-lot__boxes">
              <p v-if="userLotSelected.price && userLotSelected.price > 0" class="dashboard-card-lot__boxes-item">
                {{ parseFloat(userLotSelected.price).toLocaleString() }} {{ userLotSelected.priceMeasurement }}
              </p>
              <p v-if="userLotSelected.type" class="dashboard-card-lot__boxes-item">
                {{ userLotSelected.type }}
              </p>
            </div>
            <nuxt-link :to="{ name: clientParams ? 'client-lotId-lot' : 'lotId-lot', params: { lotId: lotIdParams } }">
              <img :src="userLotSelectedPlan3D" class="dashboard-card-lot__gallery" @clickOnImage="requestFullscreen($event)">
            </nuxt-link>
            <button class="cta dashboard-card-program__cta" @click="openLotStreamingLink(userLotSelected.flatName)">
              {{ $t('cta.visitLot') }} <img class="dashboard-card-program__cta-image" src="/icon/icon-forward-white.png" alt="icon">
            </button>
          </div>
        </section>
        <section v-else class="dashboard-card dashboard-card-program">
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }">
            <h3 class="dashboard-card__title">
              {{ $t('dashboard.program.title') }}
            </h3>
          </nuxt-link>
          <nuxt-link v-if="dashboardImagePreview" :to="{ name: clientParams ? 'client-lotId-program' : 'lotId-program', params: { lotId: lotIdParams } }" class="cards">
            <img :src="dashboardImagePreview" alt="illustration" class="dashboard-card-program__img">
          </nuxt-link>
          <button class="cta dashboard-card-program__cta" @click="openProgramStreamingLink()">
            {{ $t('cta.visitProgram') }} <img class="dashboard-card-program__cta-image" src="/icon/icon-forward-white.png" alt="icon">
          </button>
        </section>
        <section v-if="projects && projects.length" class="dashboard-card dashboard-card-projects">
          <nuxt-link :to="{ name: clientParams ? 'client-lotId-project' : 'lotId-project', params: { lotId: lotIdParams } }">
            <h3 class="dashboard-card__title">
              {{ $t('dashboard.project.title') }}
            </h3>
          </nuxt-link>
          <ProjectsItem :project="projects[0]" :index="0" />
        </section>
        <section v-else class="dashboard-card dashboard-card-projects">
          <h3 class="dashboard-card__title">
            {{ $t('dashboard.project.title') }}
          </h3>
        </section>
      </div>
      <section v-if="userStatus !=='prospect' && program.timeline_project.length > 0" id="timeline" class="dashboard-card">
        <h3 class="dashboard-card__title">
          {{ $t('dashboard.timeline.title') }}
        </h3>
        <div class="timeline">
          <ul class="timeline__box">
            <li v-for="(item, index) in program.timeline_project" :key="index" class="timeline__item" :class="{'timeline__item--checked': item.done === true}">
              <div>
                <p class="timeline__heading">
                  {{ item.title }}
                </p>
                <p class="timeline__subtitle">
                  {{ item.description }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cookies from 'js-cookie'
import DashboardNotification from '~/components/Dashboard/DashboardNotification'
import Notifications from '~/components/Notifications/Notifications'
import { getHeaders } from '~/plugins/headers'
import { CUSTOMERAREA } from '~/plugins/constants'
import { getStreamingLink } from '~/plugins/streaming'

export default {
  components: {
    DashboardNotification,
    Notifications,
  },
  data () {
    return {
      ftp: process.env.FTP,
      lotIdParams: this.$route.params.lotId,
      clientParams: this.$route.path.includes(`/${CUSTOMERAREA}`),
      promoterParams: this.$route.params.promoter,
      programParams: this.$route.params.program,
      UI: {
        visuals: false,
        localisation: false,
        infos: true,
        newsOpened: false,
      },
      docs: [],
      timeline: null,
      contact: null,
      userLotSelected: {},
    }
  },
  computed: {
    ...mapGetters({
      lots: 'lot/getLot',
      program: 'program/getProgramData',
      projects: 'projects/projects',
      messages: 'messages/getMessages',
      loggedInUserId: 'loggedInUserId',
      commercialInfos: 'program/commercialInfos',
      newsNotifications: 'news/getNotification',
      messagesNotifications: 'messages/getNotification',
      StreamingLink: 'program/getStreamingLink',
      userStatus: 'user/getUserStatus',
      linkTarget: 'options/getLinkTargetOption',
    }),
    news () {
      return this.$store.state.news.news
    },
    programAdress () {
      return `${this.program.zipCode} ${this.program.city}`
    },
    dashboardImagePreview () {
      if (this.program.previewImage && this.program.previewImage[0]) {
        return this.$prefixLinks(this.program.previewImage[0])
      }
      return this.$prefixLinks(this.program.background) || ''
    },
    lastMessage () {
      let max = 0
      let result = null

      if (this.messages) {
        for (const conversations of Object.values(this.messages)) {
          for (const messages of Object.values(conversations)) {
            const messagesList = Object.values(messages)
            for (let i = messagesList.length - 1; i >= 0; i--) {
              const dateObject = messagesList[i].time.split(' à ')
              const date = dateObject[0].replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
              const parseMessageTime = Date.parse(date + ' ' + dateObject[1])

              if (parseMessageTime && parseMessageTime > max) {
                max = parseMessageTime
                result = messagesList[i]
              }
            }
          }
        }
      }
      return result
    },
    lastNews () {
      if (this.news && this.news.postData.length > 0) {
        return this.news.postData[0]
      }
      return null
    },
    lastTimelineProject () {
      if (this.program.timeline_project && this.program.timeline_project.length > 0) {
        const itemsReaded = this.program.timeline_project.filter(item => item.done === true)
        return itemsReaded[itemsReaded.length - 1]
      }
      return null
    },
    lastTimelineFinance () {
      if (this.program.timeline_finance && this.program.timeline_finance.length > 0) {
        const itemsReaded = this.program.timeline_finance.filter(item => item.done === true)
        return itemsReaded[itemsReaded.length - 1]
      }
      return null
    },
    lastTimelineConstruction () {
      if (this.program.timeline_construction_site && this.program.timeline_construction_site.length > 0) {
        const itemsReaded = this.program.timeline_construction_site.filter(item => item.done === true)
        return itemsReaded[itemsReaded.length - 1]
      }
      return null
    },
    userLotSelectedPlan3D () {
      if (!this.userLotSelected?.floorList) { return null }

      const plans3D = this.userLotSelected?.floorList
        ?.filter(floor => floor?.planUrl3D)
        .map(floor => floor.planUrl3D)

      return plans3D.map(plan => this.$prefixLinks(plan))
    },
  },
  mounted () {
    if (this.lots) {
      this.userLotSelected = this.lots[0]
    }
  },
  methods: {
    ...mapActions({
      setNewsStatusRead: 'news/setNewsStatusRead',
      loadProgramNews: 'news/loadProgramNews',
    }),
    openNews (id, isNewsStatusRead) {
      const token = Cookies.get('temp-usr')

      this.UI.newsOpened = true
      document.body.classList.add('modal-open')
      if (!isNewsStatusRead && !this.clientParams) {
        this.setNewsStatusRead({ newsId: id, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          .then(() => {
            this.loadProgramNews({ programId: this.program.programId, userId: this.$store.state.auth.user.userId, headers: getHeaders(token) })
          })
      }
    },
    closeNews () {
      this.UI.newsOpened = false
      document.body.classList.remove('modal-open')
    },
    async openProgramStreamingLink () {
      const token = Cookies.get('temp-usr')
      const { link } = await getStreamingLink({ programId: this.program.programId, token })

      window.location.href = link

      if (this.linkTarget === '_self') {
        window.location.href = link
      } else {
        window.open(link, this.linkTarget)
      }
    },
    async openLotStreamingLink (userLotId) {
      const token = Cookies.get('temp-usr')
      const { link } = await getStreamingLink({ programId: this.program.programId, flatId: userLotId, token })

      window.open(link, this.linkTarget)
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard {
  padding: min(2rem, 6%);
  background-color: $main-light-color;
  animation: reveallight 600ms;

  &__title {
    @extend %ext-title;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 0 3rem 3rem;
  }
}

.cards-container {
  @media (min-width: $breakpoint-large) {
    display: flex;
    width: 100%;
  }
}

.dashboard-card {
  border: solid $border-color 1px;
  border-radius: $border-radius;
  padding: 1rem;
  margin-bottom: 1rem;

  &__title {
    @extend %ext-subTitle;
  }
}
.dashboard-card-program {
  &__img {
    width: auto;
    height: 15.5rem;
    object-fit: contain;
  }

  &__cta {
    font-size: $fs-link;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  &__cta-image {
    height: 0.9rem;
    width: auto;
    padding-left: 1rem;
  }
}

.dashboard-card-wrapper-2 {
  width: 100%;

  section {
    width: 100%;
    @media (min-width: $breakpoint-medium) {
      width: 49%;
    }
  }

  @media (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: space-between;
  }
}
.dashboard-card-notifications-content {
  &__notifications {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
      margin: 1px;
      width: 100%;
    }
  }

  &__lastContent {
    @media (min-width: $breakpoint-large) {
      display: flex;
      margin: 1px;
      justify-content: space-between;
      width: 100%;
    }
    a {
      width: 100%;
    }
  }
}

.dashboard-card-lot {
  &__tab {
    display: inline-block;
    border: solid $border-color 1px;
    border-radius: $border-radius $border-radius 0 0;
    min-width: 5rem;
    padding: 0.3rem 1rem;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    color: var(--second-option-color);

    &--disabled {
      opacity: 0.6;
      font-weight: 500;
      background-color: $bg-color;
    }
  }

  &__content {
    padding: 1rem;
    background-color: $bg-color;
  }

  &__boxes {
    display: flex;

    &-item {
      display: inline-block;
      background-color: $main-light-color;
      border: solid $main-medium-color 1px;
      padding: 2px 0.5rem;
      margin-right: 2px;
      margin-bottom: 4px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  &__gallery {
    margin-top: 0.4rem;
    width: auto;
    height: 13.2rem;
    object-fit: contain;
  }
}
</style>
