<template>
  <div class="modal-container" @click="closeOnClickOutside('modal-container')">
    <div class="languages">
      <UiBtnClose class="languages__btnClose" @BtnCloseClicked="$emit('clickOutside')" />
      <h2 class="languages__heading">
        {{ $t('language') }}
      </h2>
      <div>
        <button
          v-for="(lang, index) in availableLocales"
          :key="index"
          class="languages__item"
          :class="{ 'language--inactive': locale !== lang.code, 'language--active': locale === lang.code }"
          @click.prevent.stop="setLanguage(lang.code)"
        >
          {{ lang.name }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    availableLocales () {
      return this.$i18n.locales
    },
    locale () {
      return this.$i18n.locale
    },
  },
  mounted () {
    document.body.classList.add('modal-open')
  },
  beforeDestroy () {
    document.body.classList.remove('modal-open')
  },
  methods: {
    closeOnClickOutside (containerClass) {
      if (event.target.classList.value === containerClass) {
        this.$emit('clickOutside')
      }
    },
    setLanguage (languageCode) {
      this.$i18n.setLocale(languageCode)
      this.$emit('clickOutside')
    },
  },
}
</script>

<style lang="scss" scoped>
.languages {
  margin: auto;
  background-color: $main-light-color;
  border-radius: $border-radius;
  padding: 2rem;
  max-width: 43.75rem;
  position: relative;

  @media (max-width: $breakpoint-small) {
    margin: auto 1rem;
    padding: 10%;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  &__heading {
    @extend %ext-title;
    margin-bottom: 2rem;
  }

  &__item {
    border: solid $main-medium-color 1px;
    border-radius: $border-radius;
    color: $main-medium-color;
    padding: 0.5rem 1rem;
    width: 12.5rem;
    flex-grow: 1;
    transition: background-color 200ms, color 200ms;

    &:hover {
      background-color: $bg-color;
      color: $main-dark-color;
    }

    &--selected {
      background-color: $main-dark-color;
      color: $main-light-color;
    }
  }
}

.language--active {
  pointer-events: none;
  opacity: 0.5;
}
</style>
