export default (app, inject) => {
  const prefixLinks = (link) => {
    if (!link) { return null }

    if (process.client) {
      let ftp = process.env.FTP || 'ftp'
      const ftpRaw = process.env.FTP_RAW || 'ftpRaw'

      if (link.includes(ftpRaw)) {
        link = link.replace(`${ftpRaw}/`, '')
      }

      if (link.startsWith('/')) {
        link = link.replace('/', '')
      }

      if (!app.store.getters.isOnline) {
        link = `${process.env.LOCAL_IMAGES || ''}/${link.replace(`${ftp}`, '')}`
        link = link.replace('//', '/')
        ftp = process.env.LOCAL_IMAGES || ''
      }

      return link && link.includes(ftp)
        ? link
        : prefixBuilder(ftp, link)
    }
  }
  inject('prefixLinks', prefixLinks)
}

function prefixBuilder (ftp, link) {
  return ftp[ftp.length - 1] === '/'
    ? `${ftp}${link}`
    : `${ftp}/${link}`
}
