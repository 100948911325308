var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "dashboard" }, [
    _c("h2", { staticClass: "dashboard__title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("dashboard.title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dashboard-content" }, [
      _c(
        "section",
        { staticClass: "dashboard-card dashboard-card-notifications" },
        [
          _c("h3", { staticClass: "dashboard-card__title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("dashboard.notifications.title")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dashboard-card-notifications-content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "dashboard-card-notifications-content__notifications",
                },
                [
                  _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-messages"
                            : "lotId-messages",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c("Notifications", {
                        attrs: {
                          type: "lotId-messages",
                          unread: _vm.messagesNotifications || 0,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-construction"
                            : "lotId-construction",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _c("Notifications", {
                        attrs: {
                          type: "news",
                          unread: _vm.newsNotifications || 0,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dashboard-card-notifications-content__lastContent",
                },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass:
                        "dashboard-card-notifications-content__lastContent-item",
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-messages"
                            : "lotId-messages",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _vm.lastMessage
                        ? _c("DashboardNotification", {
                            attrs: {
                              content: _vm.lastMessage.subject,
                              title: _vm.$t("dashboard.messages.subtitle"),
                              date: _vm.lastMessage.time,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass:
                        "dashboard-card-notifications-content__lastContent-item",
                      attrs: {
                        to: {
                          name: _vm.clientParams
                            ? "client-lotId-construction"
                            : "lotId-construction",
                          params: { lotId: _vm.lotIdParams },
                        },
                      },
                    },
                    [
                      _vm.lastNews
                        ? _c("DashboardNotification", {
                            attrs: {
                              content: _vm.lastNews.title,
                              title: _vm.$t("dashboard.news.lastNews"),
                              date: _vm.lastNews.created_at,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass:
                    "dashboard-card-notifications-content__lastContent-item",
                  attrs: {
                    to: {
                      name: _vm.clientParams ? "client-lotId-lot" : "lotId-lot",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _vm.userStatus === "acquirer" && _vm.lastTimelineProject
                    ? _c("DashboardNotification", {
                        staticClass:
                          "dashboard-card-notifications-content__lastContent-item",
                        attrs: {
                          content: _vm.lastTimelineProject.title,
                          title: _vm.$t("dashboard.timeline.project"),
                          date: _vm.lastTimelineProject.date,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass:
                    "dashboard-card-notifications-content__lastContent-item",
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-docs"
                        : "lotId-docs",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _vm.lastTimelineFinance && _vm.userStatus === "acquirer"
                    ? _c("DashboardNotification", {
                        staticClass:
                          "dashboard-card-notifications-content__lastContent-item",
                        attrs: {
                          content: _vm.lastTimelineFinance.title,
                          title: _vm.$t("dashboard.timeline.finance"),
                          date: _vm.lastTimelineFinance.date,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nuxt-link",
                {
                  staticClass:
                    "dashboard-card-notifications-content__lastContent-item",
                  attrs: {
                    to: {
                      name: _vm.clientParams
                        ? "client-lotId-construction"
                        : "lotId-construction",
                      params: { lotId: _vm.lotIdParams },
                    },
                  },
                },
                [
                  _vm.lastTimelineConstruction
                    ? _c("DashboardNotification", {
                        staticClass:
                          "dashboard-card-notifications-content__lastContent-item",
                        attrs: {
                          content: _vm.lastTimelineConstruction.title,
                          title: _vm.$t("dashboard.timeline.construction"),
                          date: _vm.lastTimelineConstruction.date,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dashboard-card-wrapper-2" }, [
        _vm.userStatus === "acquirer" && _vm.lots
          ? _c(
              "section",
              { staticClass: "dashboard-card dashboard-card-lot" },
              [
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.clientParams
                          ? "client-lotId-lot"
                          : "lotId-lot",
                        params: { lotId: _vm.lotIdParams },
                      },
                    },
                  },
                  [
                    _c("h3", { staticClass: "dashboard-card__title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tc("dashboard.lot.title", _vm.lots.length)
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.userLotSelected
                  ? _c(
                      "div",
                      _vm._l(_vm.lots, function (userLot, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "dashboard-card-lot__tab",
                            class: {
                              "dashboard-card-lot__tab--disabled":
                                userLot.flatNumber !==
                                _vm.userLotSelected.flatNumber,
                            },
                            on: {
                              click: function ($event) {
                                _vm.userLotSelected = userLot
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            LOT " +
                                _vm._s(userLot.flatName) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userLotSelected
                  ? _c(
                      "div",
                      { staticClass: "dashboard-card-lot__content" },
                      [
                        _c(
                          "div",
                          { staticClass: "dashboard-card-lot__boxes" },
                          [
                            _vm.userLotSelected.price &&
                            _vm.userLotSelected.price > 0
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "dashboard-card-lot__boxes-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.userLotSelected.price
                                          ).toLocaleString()
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.userLotSelected.priceMeasurement
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.userLotSelected.type
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "dashboard-card-lot__boxes-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.userLotSelected.type) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            attrs: {
                              to: {
                                name: _vm.clientParams
                                  ? "client-lotId-lot"
                                  : "lotId-lot",
                                params: { lotId: _vm.lotIdParams },
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "dashboard-card-lot__gallery",
                              attrs: { src: _vm.userLotSelectedPlan3D },
                              on: {
                                clickOnImage: function ($event) {
                                  return _vm.requestFullscreen($event)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "cta dashboard-card-program__cta",
                            on: {
                              click: function ($event) {
                                return _vm.openLotStreamingLink(
                                  _vm.userLotSelected.flatName
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cta.visitLot")) +
                                " "
                            ),
                            _c("img", {
                              staticClass: "dashboard-card-program__cta-image",
                              attrs: {
                                src: "/icon/icon-forward-white.png",
                                alt: "icon",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "section",
              { staticClass: "dashboard-card dashboard-card-program" },
              [
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.clientParams
                          ? "client-lotId-program"
                          : "lotId-program",
                        params: { lotId: _vm.lotIdParams },
                      },
                    },
                  },
                  [
                    _c("h3", { staticClass: "dashboard-card__title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dashboard.program.title")) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.dashboardImagePreview
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "cards",
                        attrs: {
                          to: {
                            name: _vm.clientParams
                              ? "client-lotId-program"
                              : "lotId-program",
                            params: { lotId: _vm.lotIdParams },
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "dashboard-card-program__img",
                          attrs: {
                            src: _vm.dashboardImagePreview,
                            alt: "illustration",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "cta dashboard-card-program__cta",
                    on: {
                      click: function ($event) {
                        return _vm.openProgramStreamingLink()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("cta.visitProgram")) + " "
                    ),
                    _c("img", {
                      staticClass: "dashboard-card-program__cta-image",
                      attrs: {
                        src: "/icon/icon-forward-white.png",
                        alt: "icon",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
        _vm._v(" "),
        _vm.projects && _vm.projects.length
          ? _c(
              "section",
              { staticClass: "dashboard-card dashboard-card-projects" },
              [
                _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.clientParams
                          ? "client-lotId-project"
                          : "lotId-project",
                        params: { lotId: _vm.lotIdParams },
                      },
                    },
                  },
                  [
                    _c("h3", { staticClass: "dashboard-card__title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dashboard.project.title")) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("ProjectsItem", {
                  attrs: { project: _vm.projects[0], index: 0 },
                }),
              ],
              1
            )
          : _c(
              "section",
              { staticClass: "dashboard-card dashboard-card-projects" },
              [
                _c("h3", { staticClass: "dashboard-card__title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("dashboard.project.title")) +
                      "\n        "
                  ),
                ]),
              ]
            ),
      ]),
      _vm._v(" "),
      _vm.userStatus !== "prospect" && _vm.program.timeline_project.length > 0
        ? _c(
            "section",
            { staticClass: "dashboard-card", attrs: { id: "timeline" } },
            [
              _c("h3", { staticClass: "dashboard-card__title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.timeline.title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "timeline" }, [
                _c(
                  "ul",
                  { staticClass: "timeline__box" },
                  _vm._l(_vm.program.timeline_project, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "timeline__item",
                        class: {
                          "timeline__item--checked": item.done === true,
                        },
                      },
                      [
                        _c("div", [
                          _c("p", { staticClass: "timeline__heading" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.title) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "timeline__subtitle" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.description) +
                                "\n              "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }